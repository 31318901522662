const config = {
  apiGateway: {
    REGION: 'us-east-2',
    INTERNAL_API_URL: 'https://vmtjobn22a.execute-api.us-east-2.amazonaws.com/prod',
    COMPANY_API_URL: 'https://zyxla99546.execute-api.us-east-2.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'taxonomy-company-1.11.0',
    HOSTNAME: 'https://company.testing.vale.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.testing.vale.forwoodsafety.com',
    WEBSOCKET: 'wss://mwauy6oed8.execute-api.us-east-2.amazonaws.com/testing'
  },
};

export default config;